/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import HomeVideo from '../video/home.mp4';
import VideoPoster from '../images/poster-home.jpg';
import Open24Img from '../images/icons/open-24-hours.svg';
import CareImg from '../images/icons/care.svg';
import MedicalTeamImg from '../images/icons/medical-team.svg';
import StayHomeImg from '../images/icons/stay-at-home.svg';
import Layout from '../components/Layout/Layout';

const IndexPage = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />
      {/* HERO */}
      <div className="hero home-hero d-flex align-items-lg-center justify-content-between p-relative pt-3 pt-lg-0">
        <Container className="w-100">
          <h1 className="text-primary fw-bold fs-1">WE BELIEVE</h1>
          <div className="hero__morphing">
            <div className="hero__word fs-3">
              Healthcare Should Come Home to the Patient
            </div>
            <div className="hero__word fs-3">
              Respect and Compassion Are as Effective as Medication
            </div>
            <div className="hero__word fs-3">Access to Care Is Foremost</div>
            <div className="hero__word fs-3">
              In Health Equity, Diversity and Inclusion
            </div>
          </div>
        </Container>
      </div>

      {/* VIDEO */}
      <section className="bg-gray py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="p-1 bg-white shadow-lg rounded-3">
                <div className="ratio ratio-16x9">
                  <video poster={VideoPoster} controls>
                    <source src={HomeVideo} type="video/mp4" />
                    Your browser does not support the video tag
                  </video>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* SERVICES */}
      <Container className="py-6">
        <Row className="justify-content-center mb-5">
          <Col lg={6} className="text-center">
            <h2>OUR SERVICES</h2>
            <p>
              Presidium Health is dedicated to bringing holistic healthcare to
              our patients 24/7 365 days a year, wherever they call home
            </p>
          </Col>
        </Row>
        <Row className="py-4">
          <Col lg={3} sm={6} className="text-center mb-5">
            <img src={Open24Img} alt="" />
            <p className="mb-0 mt-4">24/7 Access</p>
          </Col>
          <Col lg={3} sm={6} className="text-center mb-5">
            <img src={CareImg} alt="" />
            <p className="mb-0 mt-4">Personalized Care Team</p>
          </Col>
          <Col lg={3} sm={6} className="text-center mb-5">
            <img src={MedicalTeamImg} alt="" />
            <p className="mb-0 mt-4">Whole Person Care</p>
          </Col>
          <Col lg={3} sm={6} className="text-center mb-5">
            <img src={StayHomeImg} alt="" />
            <p className="mb-0 mt-4">Care at Home</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="primary" as={Link} to="/services/">
              Read more about our services
            </Button>
          </Col>
        </Row>
      </Container>

      {/* IMPACT */}
      <section className="bg-gray py-5">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg={6} className="text-center">
              <h2>IMPACT</h2>
              <p>
                We specialize in engaging and caring for the top 1% highest
                utilizing Medicaid patients, drastically reducing hospital
                utilization and costs
              </p>
            </Col>
          </Row>
          <Row className="justify-content-xl-center">
            <Col lg={4} xl={3} className="mb-4">
              <Card className="shadow border-0 rounded-3">
                <Card.Body className="text-center">
                  <StaticImage
                    src="../images/home-impact-population.png"
                    alt="Population"
                    className="rounded-3 mb-4"
                  />
                  <Card.Title>Population</Card.Title>
                  <Card.Text className="fs-7">
                    Presidium Health specializes in helping the patients who
                    <span>need it most</span>
                  </Card.Text>
                  <Link to="/impact/">Learn More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} xl={3} className="mb-4 mb-lg-0">
              <Card className="shadow border-0 rounded-3">
                <Card.Body className="text-center">
                  <StaticImage
                    src="../images/home-impact-engagement.png"
                    alt="Engagement"
                    className="rounded-3 mb-4"
                  />

                  <Card.Title>Engagement</Card.Title>
                  <Card.Text className="fs-7">
                    We engage 90% of patients with complex medical and social
                    <span>needs</span>
                  </Card.Text>
                  <Link to="/impact/">Learn More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} xl={3} className="mb-4 mb-lg-0">
              <Card className="shadow border-0 rounded-3">
                <Card.Body className="text-center">
                  <StaticImage
                    src="../images/home-impact-utilization.png"
                    alt="Utilization"
                    className="rounded-3 mb-4"
                  />
                  <Card.Title>Utilization</Card.Title>
                  <Card.Text className="fs-7">
                    Proactive and preventative care is key to our success in
                    reducing hospital utilization
                  </Card.Text>
                  <Link to="/impact/">Learn More</Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default IndexPage;
